<template>
  <!-- Start Service Area  -->
  <v-row class="service-main-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="text-left service service__style--2">
        <!-- <router-link to="/service-details"> -->
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        <!-- </router-link> -->
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
        {
          icon: "smartphone",
          title: this.$t('mobileAppsTitle'),
          desc: this.$t('mobileAppsDesc'),
        },
        {
          icon: "monitor",
          title: this.$t('webSystemsTitle'),
          desc: this.$t('webSystemsDesc'),
        },
        {
          icon: "briefcase",
          title: this.$t('startupConsultingTitle'),
          desc: this.$t('startupConsultingDesc'),
        },
        {
          icon: "users",
          title: this.$t('devAllocationTitle'),
          desc: this.$t('devAllocationDesc'),
        },
        {
          icon: "copy",
          title: this.$t('maintenanceEvolutionTitle'),
          desc: this.$t('maintenanceEvolutionDesc'),
        },
        {
          icon: "layers",
          title: this.$t('aiIntegratedSolutionsTitle'),
          desc: this.$t('aiIntegratedSolutionsDesc'),
        },
      ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
